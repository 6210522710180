<template>
  <div>
    <x-header>
      <img slot="headerImage" src="../../../assets/kongyunfuwu/banner.jpg" alt="">
      <h1 slot="headerTitle">{{ $store.state.navShow == true ? '境外承运——优势价格与高时效兼具' : "Overseas transportation—— combining advantageous prices and high timeliness"}}</h1>
    </x-header>
    <div class="outer">
      <div class="inter_main">
        <div class="guoji_Items" v-for="(item, index) in $store.state.navShow == true ? guojiItemsArr : guojiItemsArrE"
          :key="index">
          <div class="guoji_Items_l" :data-aos="index % 2 == 0 ? 'fade-right' : 'fade-left'" data-aos-offset="300"
            data-aos-easing="ease-in-sine">
            <img :class="'guoji_Items_l_img guoji_Items_l_img' + index" :src="item.itemsLeftImg" alt="">
          </div>
          <div class="guoji_Items_r">
            <div :class="$store.state.navShow == true ? 'content_kuang' : 'content_kuangE'">
              <p class="items_title">{{ item.itemsRightTitle }}</p>
              <p :class="'items_content items_content' + index">{{ item.items_content1 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sign_img">
        <img v-if="$store.state.navShow == true" src="../../../assets/zhongoubanliejingwaifuwushang/yunlidiaodong.jpg"
          alt="" /><img v-else src="../../../assets/zhongoubanliejingwaifuwushang/yunlidiaodongE.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：境外承运
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2023-08-12 10:13
 */
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      guojiItemsArr: [
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy1.png'),
          itemsRightTitle: '优势价格，降低运输成本。',
          items_content1: 'MG集团与哈铁、俄铁直接合作，重点发展境外承运业务，共同打造阿拉山口、霍尔果斯到俄罗斯的运输线路，并取得了具有市场优势的运输价格。2023年顺利发行二连浩特及满洲里口岸，境外承运班列发运量达105列/月。'
        },
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy2.png'),
          itemsRightTitle: '时效高，口岸换装优先受理。',
          items_content1: 'MG集团在中哈边境口岸（阿拉山口、霍尔果斯）拥有合作堆场，为班列提供优先受理服务。自营10000个车板，可实现在边境口岸高效换装，这都为打造“高运力、高时效”的服务质量提供坚实保障。'
        },
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy3.png'),
          itemsRightTitle: '经验丰富，高效性问题方案解决能力强。',
          items_content1: 'MG集团与莫斯科各场站有密切深入的合作关系，专业运营团队在解决目的站接车、拥堵、口岸等问题方面提出的解决方案切实提高了时效问题，具备很强的协议能力。'
        }
      ],
      guojiItemsArrE: [
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy1.png'),
          itemsRightTitle: 'Advantageous prices and lower transportation costs.',
          items_content1: 'MG Group cooperates directly with Kha Railway and Russian Railway, focusing on the development of offshore carrier business, and jointly builds transportation routes from Alashankou and Khorgos to Russia, and obtains transportation prices with market advantages. In 2023, Erenhot and Manzhouli ports were successfully launched, with a shipment volume of 105 overseas freight trains per month.'
        },
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy2.png'),
          itemsRightTitle: 'Highly time-sensitive, with priority processing for port changeover.',
          items_content1: 'MG Group owns cooperative yards at Harbin border ports (Alashankou and Khorgos), which provide priority service for the freight train. MG Group has 10,000 self-managed vehicle boards, which can be efficiently loaded and unloaded at the border ports, all of which provide a solid guarantee for the service quality of "high capacity and high time efficiency".'
        },
        {
          itemsLeftImg: require('../../../assets/jingwaichengyun/jwcy3.png'),
          itemsRightTitle: 'Experienced and highly efficient problem-solving skills.',
          items_content1: 'MG Group has a close and intensive cooperation with Moscow terminals. The solutions proposed by our professional operations team in solving the problems of pick-ups, congestion and crossings at the destination stations effectively improve the timeliness issues and have a strong protocol capacity.'
        }
      ]
    }
  },
  mounted () { },
  methods: {}
}
</script>
<style lang='less' scoped>
.outer {
  width: var(--width1200px);
  margin: 117px auto 60px;
  text-align: left;

  &>p {
    font-family: Microsoft YaHei;
    color: #666666;

  }

  .inter_main {
    width: var(--width1200px);
    margin: 115px auto 0;

    &>p {
      font-family: Microsoft YaHei;
      color: #666666;
      text-align: left;
      text-indent: 32px;
      margin-bottom: 75px;
    }

    .guoji_Items {
      width: 100%;
      height: 280px;
      // background: #2e67b1;
      background: linear-gradient(-90deg, rgba(46, 103, 177, 0.1) 0%, rgba(46, 103, 177, 0) 100%);
      margin-bottom: 90px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      position: relative;

      .guoji_Items_l {
        // width: 757px;
        // height: 100%;
        // background: orange;
        position: relative;

        .guoji_Items_l_img0 {
          width: 510px;
          height: 294px;
          margin: 53px 0 0 0;
        }

        .guoji_Items_l_img1 {
          width: 329px;
          height: 300px;
          margin: 11px 130px 0 0;
        }

        .guoji_Items_l_img2 {
          width: 330px;
          height: 260px;
          margin: 35px 0 0 118px;
        }

      }

      .guoji_Items_r {
        width: 54%;
        background: #2e67b1;

        .content_kuang {
          // width: 176px;
          // background: orange;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // justify-content: center;
          color: #FFFFFF;
          font-family: Microsoft YaHei;
          margin: 50px 50px 10px;

          &>.items_title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 40px;
          }

          &>.items_content {
            font-size: 16px;
            line-height: 37px;
            // text-align: left;
          }

        }

        .content_kuangE {
          // width: 176px;
          // background: orange;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // justify-content: center;
          color: #FFFFFF;
          font-family: Microsoft YaHei;
          margin: 30px 50px 10px;

          &>.items_title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          &>.items_content {
            font-size: 16px;
            line-height: 25px !important;
            // text-align: left;
          }

        }

      }

      &:nth-child(even) {

        // background: green;
        //  flex-direction: row-reverse;
        .guoji_Items_r {
          .content_kuang {
            &>.items_content {
              font-size: 16px;
              line-height: 37px;
              text-align: left;
            }
          }

          .content_kuangE {
            &>.items_content {
              font-size: 16px;
              line-height: 30px;
              text-align: left;
            }
          }
        }
      }

      &:nth-child(even) {
        // background: green;
        flex-direction: row-reverse;
        background: linear-gradient(90deg, rgba(46, 103, 177, 0.1) 0%, rgba(46, 103, 177, 0) 100%) !important;

        .guoji_Items_r {
          .content_kuang {
            text-align: right;
            align-items: flex-end;
            margin: 50px 50px 10px;

            &>img {
              float: right;
            }
          }

          .content_kuangE {
            text-align: right;
            align-items: flex-end;
            margin: 40px 50px 10px;

            &>img {
              float: right;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  .sign_img {
    margin-top: 67px;
  }
}
</style>
